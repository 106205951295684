import {scrollToY, Easing } from './scrollto'
declare var baguetteBox: any;

document.getElementsByClassName('navbar')[0].addEventListener('click', function(e: MouseEvent) {
    let element = e.target as HTMLAnchorElement;
    if (element && element.nodeName == 'A' && element.host == window.location.host) {

        let anchor = document.getElementById(element.hash.slice(1));

        if (anchor) {
            let rect = anchor.getBoundingClientRect();
            let anchorOffset = window.pageYOffset + rect.top - (window.innerHeight / 5);
            scrollToY(anchorOffset, 1000, Easing.easeInOutQuint);
        }

        e.preventDefault();
    }
});

var scrolled = false;

function checkScroll() {
    if (scrolled) {
        let display = false;

        let logo = document.getElementById('logo');
        if (logo) {
            let rect = logo.getBoundingClientRect();
            display =  (-rect.top > 0.75 * rect.height);
        } else {
            display = window.pageYOffset > 100;
        }
        if (display)
            document.getElementsByClassName('navbar')[0].classList.add('show');
        else
            document.getElementsByClassName('navbar')[0].classList.remove('show');

        // Check active link
        let menuItems = document.getElementsByClassName('navbar')[0].getElementsByTagName('ul')[0].children;
        var currentMenuItem;

        for (var i=0; i < menuItems.length; i++) {
            menuItems[i].classList.remove('active');
            let linkItem = menuItems[i].children[0] as HTMLAnchorElement;
            if (linkItem) {
                let hash = linkItem.hash.slice(1);
                
                if (hash) {
                    let anchor = document.getElementById(hash);
                    if (anchor && anchor.getBoundingClientRect().top < window.innerHeight / 2 + 10)
                        currentMenuItem = menuItems[i];
                }
            }
        }

        if (currentMenuItem)
            currentMenuItem.classList.add('active');

        scrolled = false;
    }

    setTimeout(checkScroll, 100);
}

checkScroll();

document.onscroll = function(e) {
    scrolled = true;
}

window.addEventListener('DOMContentLoaded', function() {
    baguetteBox.run('.gallery');
});

// AJAX Forms
window.addEventListener('submit', function(e: Event) {
    var form = e.target as HTMLFormElement;
    if (form.classList.contains('form-ajax')) {
        e.preventDefault();
        setFormReadonly(form, true);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            console.log(this);
            if (this.readyState == 4 && this.status == 200) {
                form.outerHTML = this.responseText;
            }
        }
        var action = form.attributes.getNamedItem('action').value;
        var method = form.attributes.getNamedItem('method').value;
        var data = urlEncodeForm(form);
        console.log("Action", action);
        console.log("Method", method);
        console.log("Data", data);
        xhttp.open(method, action, true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send(data);
    }
});

function urlEncodeForm(form: HTMLFormElement) {
    var list = [];
    for (var idx in form.elements) {
        var element = form.elements[idx] as HTMLFormElement;
        list.push(element.name + '=' + encodeURIComponent(element.value));
    }

    return list.join('&');
}

function setFormReadonly(form: HTMLFormElement, readonly: boolean) {
    for (var idx in form.elements) {
        if (typeof form.elements[idx] === 'object') {
            var element = form.elements[idx] as HTMLFormElement;
            console.log(element);
            element.disabled = readonly;    
        }
    }
}
