export enum Easing {
    easeOutSine = 'easeOutSine',
    easeInOutSine = 'easeInOutSine',
    easeInOutQuint = 'easeInOutQuint'
}

type EasingFunc = {
    (pos: number): number;
}

type EasingFuncMap = {
    [key in Easing]: EasingFunc
}

export function scrollToY(scrollTargetY: number, speed: number, easing: Easing) {
    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use

    var scrollY = window.scrollY || document.documentElement.scrollTop,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 2000,
        easing = easing || Easing.easeOutSine,
        currentTime = 0;

    // min time .1, max time .8 seconds
    var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    var easingEquations: EasingFuncMap = {
            easeOutSine: function (pos: number) {
                return Math.sin(pos * (Math.PI / 2));
            },
            easeInOutSine: function (pos: number) {
                return (-0.5 * (Math.cos(Math.PI * pos) - 1));
            },
            easeInOutQuint: function (pos: number) {
                if ((pos /= 0.5) < 1) {
                    return 0.5 * Math.pow(pos, 5);
                }
                return 0.5 * (Math.pow((pos - 2), 5) + 2);
            }
        };

    // add animation loop
    function tick() {
        currentTime += 1 / 60;

        var p = currentTime / time;
        var t = easingEquations[easing](p);

        if (p < 1) {
            window.requestAnimationFrame(tick);

            window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
            window.scrollTo(0, scrollTargetY);
        }
    }

    // call it once to get started
    tick();
}
